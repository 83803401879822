import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import SEO from "../components/seo";
import Container from "../components/container";
import Elevator from "../components/elevator";
import Layout from "../components/layout";
import "../styles/layout.scss";

import arrowRight from "../assets/arrow-right.svg";

export const query = graphql`
  query CoachingQuery {
    coachingPage: allSanityPage(filter: { name: { eq: "Coaching" } }) {
      edges {
        node {
          name
          _rawBody
          _rawExtraBody
          _id
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
    bikeImage: allSanityPage(filter: { name: { eq: "Sykkelcoaching" } }) {
      edges {
        node {
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }

    runImage: allSanityPage(filter: { name: { eq: "Løpecoaching" } }) {
      edges {
        node {
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
  }
`;

const CoachingPage = () => {
  const data = useStaticQuery(query);
  const pageEdge = mapEdgesToNodes(data.coachingPage);
  const elevatorContent = pageEdge[0] || [];

  const bikeImageArr = mapEdgesToNodes(data.bikeImage);
  const runImageArr = mapEdgesToNodes(data.runImage);

  const bikeImage = bikeImageArr[0].image || [];
  const runImage = runImageArr[0].image || [];

  console.log(bikeImage);
  console.log(runImage);

  return (
    <Layout>
      <SEO title="Coaching" />
      <div className="elevator__background"></div>

      <Container>
        <div className="coaching">
          <Elevator
            title={elevatorContent.name}
            image={elevatorContent.image}
            info={elevatorContent._rawBody}
            extraInfo={elevatorContent._rawExtraBody}
          />
          <div className="coaching__coach-wrapper">
            <div className="coaching__coach">
              <Link className="coaching__coach-link" to="/coaching/lopecoaching">
                <h3 className="coaching__coach-title">Løpecoaching</h3>
                <img
                  className="coaching__coach-arrow"
                  src={arrowRight}
                  alt="gå videre til om løpetreneren"
                />
                {runImage && runImage.asset && (
                  <img
                    className="coaching__coach-img"
                    src={imageUrlFor(buildImageObj(runImage))
                      .width(600)
                      .height(600)
                      .fit("crop")
                      .auto("format")
                      .url()}
                    alt={runImage.alt}
                  />
                )}
              </Link>
            </div>
            <div className="coaching__coach">
              <Link className="coaching__coach-link" to="/coaching/sykkelcoaching">
                <h3 className="coaching__coach-title">Sykkelcoaching</h3>
                <img
                  className="coaching__coach-arrow"
                  src={arrowRight}
                  alt="gå videre til om løpetreneren"
                />
                {bikeImage && bikeImage.asset && (
                  <img
                    className="coaching__coach-img"
                    src={imageUrlFor(buildImageObj(bikeImage))
                      .width(500)
                      .height(500)
                      .fit("crop")
                      .auto("format")
                      .url()}
                    alt={bikeImage.alt}
                  />
                )}
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default CoachingPage;
